import logo from './logo.svg';
import './App.css';

function App() {

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  
  return (
    <div className="App">
      <div className="content">
        <div className="logo">
          <div className="circle-empty"></div>
          <div className="circle-full"></div>
        </div>
        <p className="description">Radical aims to help researchers generate insight faster.</p>
        <a href="mailto:olly.hunt123@gmail.com">
          <div className="button">
            Get in touch
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;
